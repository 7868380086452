import {computed} from 'vue'
import {useStore} from 'vuex'
import {ACTION_TYPES} from "@/constants";

export default function () {
	const store = useStore();
	const voucher = computed(() => store.getters.bookedVoucher);
	const loadVoucher = (route, router) => {
		const voucherCode = route.params.code;
		if (!voucher.value) {
			store.dispatch(ACTION_TYPES.findBookedVoucher, voucherCode)
				.then(() => {
					if (!voucher.value) {
						router.push({name: 'admin_search', params: route.params});
					}
				})
		}
	}

	return {
		voucher,
		loadVoucher
	}
}