<template>
  <template v-if="!loading">
    <header-line></header-line>
    <div class="content">
      <div class="site-width">
        <div class="system__page">
          <div class="system__voucher">
            <div class="card card--noimage card--info">
              <div class="card__block">
                <div class="card__label">{{ t('label.voucher') }}</div>
                <div class="card__title">{{ voucher.name }}</div>
                <div class="card__description" v-html="voucherInfo ? voucherInfo.description : ''"></div>
                <div class="card__options" v-if="voucher.priceType !== 'FIXED' && voucher.variant">
                  <div class="card__label">{{ t('label.option') }}</div>
                  {{ getSelectOptions(voucherInfo, data.currencySymbol)[voucher.variant] }}
                </div>
                <div class="card__price">
                  <div class="card__label">{{ t('label.retail_value') }}</div>
                  {{ numberFormat(voucher.amount, data.currencySymbol, true) }}
                </div>
              </div>
            </div>
            <div class="card__info">
              <div class="info__column">
                <div class="card__label">{{ t('label.delivery_to') }}</div>
                {{ voucher.deliveryEmail }}
              </div>
              <div class="info__column">
                <div class="card__label">{{ t('label.recipient') }}</div>
                {{ voucher.firstName }} {{ voucher.lastName }}
              </div>
              <div class="info__column">
                <div class="card__label">{{ t('label.voucher_code') }}</div>
                {{ voucher.voucherCode }}
              </div>
              <div class="info__bg">
              </div>
            </div>
          </div>
          <div class="system__history">
            <div class="history">
              <div class="history__title">{{ t('label.operations_history') }}</div>
              <div class="history__table" v-if="voucher.redemptions.length">
                <table class="table" width="100%" cellspacing="0" cellpadding="0">
                  <tbody>
                  <tr class="table__row">
                    <th class="table__cell table__cell--header">{{ t('label.date') }}</th>
                    <th class="table__cell table__cell--header">{{ t('label.user') }}</th>
                    <th class="table__cell table__cell--header">{{ t('label.amount') }}</th>
                  </tr>
                  <template v-for="redemption in voucher.redemptions" v-bind:key="redemption.date">
                    <tr class="table__row">
                      <td class="table__cell table__cell--datetime">
                        {{ redemption.date }}
                        <!--                      <span class="date">25/10/2020</span> 16:49-->
                      </td>
                      <td class="table__cell">{{ redemption.manager }}</td>
                      <td class="table__cell table__cell--amount">
                        -{{ numberFormat(redemption.amount, data.currencySymbol, true) }}
                      </td>
                    </tr>
                    <tr class="table__row table__row--noborder row-comment" v-if="redemption.comment">
                      <td class="table__cell" colspan="3"><strong>{{ t('label.comment') }}:</strong>
                        {{ redemption.comment }}
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
              <div class="history__label">{{ t('label.amount_left') }}</div>
              <div class="history__amount" v-bind:class="{'no-amount': amountLeft <= 0}">
                {{ numberFormat(amountLeft, data.currencySymbol) }}
              </div>
              <div class="history__btns" v-if="amountLeft > 0">
                <router-link
                    v-bind:to="{name: 'admin_redeem', params: {code: voucherCode, lang}}"
                    v-bind:class="'btns btn-orange'"
                >
                  {{ t('label.redeem_voucher') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-small-line></footer-small-line>
  </template>
  <page-loader v-else></page-loader>
</template>

<script>
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex'
import {watchEffect, ref, computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import HeaderLine from "@/components/HeaderLine";
import useAdmin from "@/helpers/useAdmin";
import useAdminVoucher from "@/helpers/useAdminVoucher";
import PageLoader from "@/components/PageLoader";
import FooterSmallLine from "@/components/FooterSmallLine";
import {getSelectOptions} from "@/helpers/VoucherVariantsHelper";
import {numberFormat} from "@/helpers/PriceHelper";

export default {
  name: 'admin-voucher',
  components: {FooterSmallLine, PageLoader, HeaderLine},
  setup() {
    const loading = ref(true);
    const store = useStore();

    const {voucher, loadVoucher} = useAdminVoucher();
    const router = useRouter();
    const route = useRoute();
    loadVoucher(route, router);

    const {admin, redirectIfNotLoggedIn} = useAdmin();

    const data = computed(() => store.getters.data);

    watchEffect(() => {
      redirectIfNotLoggedIn(router);
      loading.value = !data.value || !voucher.value || !admin.value;
    })

    const voucherInfo = computed(
        () => data.value.vouchers && voucher.value
            ? data.value.vouchers.find(el => (el.id === voucher.value.cmsContentId))
            : null
    );

    const amountLeft = computed(() => voucher.value.amountLeft ? voucher.value.amountLeft / 100 : 0);

    const i18n = useI18n();

    return {
      t: i18n.t,
      voucher,
      data,
      loading,
      voucherInfo,
      amountLeft,
      numberFormat,
      getSelectOptions,
      voucherCode: route.params.code
    };
  }
}
</script>

<style>
.row-comment td {
  padding-top: 0!important;
}
</style>